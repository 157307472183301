import React, { useEffect } from "react";
import { onBlankLayout } from "store/slices/themeSlice";
import { useDispatch } from "react-redux";
import {
  loadSettingDetails,
  settingDetails,
} from "services/appServices/settingDetailService";
import { getSettings } from "store/slices/settingSlice";
import { useSelector } from "react-redux";

const AppRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  ...props
}) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state?.settings?.data);
  useEffect(() => {
    settingDetails().then((res) => {
        // console.log(res?.data, "sdkdjsdjkjsdksdkjsdkjsdkskjsdk");
      if (res?.status === 200) {
        dispatch(getSettings(res?.data));
      }
    });
  }, []);

  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));
  }, [blankLayout]);

  return <Component {...props} />;
};

export default AppRoute;
