import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isToggle: false,
};

const clickOutSideSlice = createSlice({
  name: "site details",
  initialState,
  reducers: {
    clickOutSide: (state, action) => {
      state.isToggle = action.payload;
    },
  },
});

export const { clickOutSide } = clickOutSideSlice.actions;
export default clickOutSideSlice.reducer;
