import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY,
} from "configs/AppConfig";
import { verifyToken } from "services/appServices/auth";
import Loading from "components/shared-components/Loading";
import { authDispatch } from "store/slices/authSlice";



const ProtectedRoute = () => {
  const [authenticated, setAuthenticated] = useState({
    loaded: false,
    verified: false,
  });
  const token = localStorage.getItem("auth_token");
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    verifyToken(setAuthenticated).then((data) =>
      dispatch(authDispatch(data.data))
    );
  }, []);



  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }

  if (!authenticated.loaded) {
    return <Loading />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
