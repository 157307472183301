import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() =>
      import("views/app-views/pages/Settings/EditProfile")
    ),
  },
  //Admin
  {
    key: "user.admin",
    path: `${APP_PREFIX_PATH}/members-management/admin`,
    component: React.lazy(() =>
      import("views/app-views/pages/admins/AdminList")
    ),
  },
  {
    key: "user.admin.add",
    path: `${APP_PREFIX_PATH}/members-management/add-admin`,
    component: React.lazy(() =>
      import("views/app-views/pages/admins/AdminAdd")
    ),
  },
  {
    key: "user.admin.edit",
    path: `${APP_PREFIX_PATH}/members-management/admin/edit-admin/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/admins/AdminAdd")
    ),
  },
  //User
  {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/members-management/user/userlist`,
    component: React.lazy(() => import("views/app-views/pages/user/userList")),
  },
  {
    key: "user.users.add",
    path: `${APP_PREFIX_PATH}/members-management/user/user-add`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.edit",
    path: `${APP_PREFIX_PATH}/members-management/user/user-edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.profile",
    path: `${APP_PREFIX_PATH}/members-management/user/user-profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/UserProfile")
    ),
  },

  //Employee
  {
    key: "user.employee",
    path: `${APP_PREFIX_PATH}/members-management/employee`,
    component: React.lazy(() =>
      import("views/app-views/pages/employee/EmployeeList")
    ),
  },
  {
    key: "user.employee.add",
    path: `${APP_PREFIX_PATH}/members-management/employee/employee-add`,
    component: React.lazy(() =>
      import("views/app-views/pages/employee/EmployeeAdd")
    ),
  },
  {
    key: "user.employee.add",
    path: `${APP_PREFIX_PATH}/members-management/employee/employee-edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/employee/EmployeeAdd")
    ),
  },
  {
    key: "user.employee.profile",
    path: `${APP_PREFIX_PATH}/members-management/employee/employee-profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/UserProfile")
    ),
  },

  //Category

  {
    key: "categories.categories",
    path: `${APP_PREFIX_PATH}/categories`,
    component: React.lazy(() =>
      import("views/app-views/pages/categories/CategoryList")
    ),
  },
  {
    key: "categories.sub-categories",
    path: `${APP_PREFIX_PATH}/sub-category`,
    component: React.lazy(() =>
      import("views/app-views/pages/categories/SubCategoryList")
    ),
  },
  {
    key: "categories.topic",
    path: `${APP_PREFIX_PATH}/topic`,
    component: React.lazy(() =>
      import("views/app-views/pages/categories/TopicList")
    ),
  },

  // quiz===========>
  {
    key: "quiz.lists",
    path: `${APP_PREFIX_PATH}/quizzes/lists`,
    component: React.lazy(() => import("views/app-views/pages/quiz/QuizLists")),
  },
  {
    key: "quiz.add",
    path: `${APP_PREFIX_PATH}/quiz/add`,
    component: React.lazy(() => import("views/app-views/pages/quiz/QuizAdd")),
  },
  {
    key: "quiz.edit",
    path: `${APP_PREFIX_PATH}/quiz/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/quiz/QuizAdd")),
  },
  {
    key: "quiz.results",
    // path: `${APP_PREFIX_PATH}/quiz/result/:id`,
    path: `${APP_PREFIX_PATH}/quiz/result/*`,
    component: React.lazy(() => import("views/app-views/pages/quiz/Results")),
  },

  {
    key: "quiz.banners.list",
    path: `${APP_PREFIX_PATH}/quiz/banners`,
    component: React.lazy(() =>
      import("views/app-views/pages/banners/BannerList")
    ),
  },
  {
    key: "quiz.banners.add",
    path: `${APP_PREFIX_PATH}/quiz/banners/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/banners/AddBanner")
    ),
  },
  {
    key: "quiz.banners.edit",
    path: `${APP_PREFIX_PATH}/quiz/banners/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/banners/AddBanner")
    ),
  },

  // question sec =============>

  {
    key: "question.add",
    path: `${APP_PREFIX_PATH}/question/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/question/QuestionAdd")
    ),
  },
  {
    key: "question.edit",
    path: `${APP_PREFIX_PATH}/question/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/question/QuestionAdd")
    ),
  },
  {
    key: "question.list",
    path: `${APP_PREFIX_PATH}/questions`,
    component: React.lazy(() =>
      import("views/app-views/pages/question/QuestionList")
    ),
  },

  // coupon sec===========>

  {
    key: "coupon.list",
    path: `${APP_PREFIX_PATH}/coupons`,
    component: React.lazy(() =>
      import("views/app-views/pages/coupon/CouponList")
    ),
  },

  // Quiz Name=======>

  {
    key: "quiz.quiz-name",
    path: `${APP_PREFIX_PATH}/quizzes/quiz-name`,
    component: React.lazy(() =>
      import("views/app-views/pages/quizName/QuizName")
    ),
  },

  // Transection section

  {
    key: "transactions.list",
    path: `${APP_PREFIX_PATH}/transactions`,
    component: React.lazy(() =>
      import("views/app-views/pages/transection/TransectionList")
    ),
  },
  {
    key: "transactions.details",
    path: `${APP_PREFIX_PATH}/transactions/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/transection/TransactionDetails")
    ),
  },
  {
    key: "withdrawals.list",
    path: `${APP_PREFIX_PATH}/withdrawals`,
    component: React.lazy(() =>
      import("views/app-views/pages/transection/WithdrawalList")
    ),
  },

  // Referrals section

  {
    key: "referrals.list",
    path: `${APP_PREFIX_PATH}/referrals`,
    component: React.lazy(() =>
      import("views/app-views/pages/referrals/ReferralsList")
    ),
  },

  // CMS Management===> Pages (Sakil)

  {
    key: "cms.pages.list",
    path: `${APP_PREFIX_PATH}/cms/pages`,
    component: React.lazy(() =>
      import("views/app-views/pages/sitePages/SitePages")
    ),
  },
  {
    key: "cms.pages.add",
    path: `${APP_PREFIX_PATH}/cms/pages/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/sitePages/AddSitePage")
    ),
  },
  {
    key: "cms.pages.update",
    path: `${APP_PREFIX_PATH}/cms/pages/add/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/sitePages/AddSitePage")
    ),
  },

  // CMS Management===> Faq's (Sakil)

  {
    key: "cms.faq.list",
    path: `${APP_PREFIX_PATH}/cms/faq`,
    component: React.lazy(() => import("views/app-views/pages/faq/FaqList")),
  },
  {
    key: "cms.faq.add",
    path: `${APP_PREFIX_PATH}/cms/faq/add`,
    component: React.lazy(() => import("views/app-views/pages/faq/AddFaq")),
  },
  {
    key: "cms.faq.edit",
    path: `${APP_PREFIX_PATH}/cms/faq/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/faq/AddFaq")),
  },

  //CMS Management ====> Settings

  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/cms/settings`,
    component: React.lazy(() =>
      import("views/app-views/pages/Settings/SiteSettings")
    ),
  },

  //CMS Management =====> Banners

  {
    key: "cms.enquiry.list",
    path: `${APP_PREFIX_PATH}/cms/enquiry`,
    component: React.lazy(() =>
      import("views/app-views/pages/enquiry/EnquiryList")
    ),
  },
];
