import { apiRequest } from "utils/async/apiUtils";

export const loadSettingDetails = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", "site-settings");

    const data = res.data.data;
    setResData(data);
    setLoaded(true);
    return data;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};


export const settingDetails = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  try {
    const res = await apiRequest("get", "site-settings");

    const data = res.data;
    if (data.status === 200) setResData(data.data);
    setLoaded(true);
    return data
  } catch (err) {
    setLoaded(true);
    return err
  }
};

export const editSettings = async (data, setIsLoaded = () => {}) => {
  let dataQuery = {};
  data?.socialMedia.length > 0 &&
    data.socialMedia.map((item, index) => {
      dataQuery[`socialMedia[${index}][name]`] = item.name;
      dataQuery[`socialMedia[${index}][url]`] = item.url;
    });
  data?.fakeUsers &&
    data?.fakeUsers?.forEach(
      (item, i) => (dataQuery[`fakeUsers[${i}]`] = item)
    );

  setIsLoaded(false);
  try {
    const res = await apiRequest(
      "put",
      "site-settings",
      {
        body: {
          ...dataQuery,
          title: data?.title,
          description: data?.description,
          email: data?.email,
          phone: data?.phone,
          "address[address1]": data?.address,
          logo: data?.fileList,
          favicon: data?.favIcon,
          referralAmount: data?.referralAmount,
          referrerAmount: data?.referrerAmount,
        },
      },
      "multipart/form-data"
    );
    setIsLoaded(true);
    return res;
  } catch (error) {
    setIsLoaded(true);
    return error.response;
  }
};
